import { LANGUAGES, LANGUAGE_KEYS } from './languages'

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enMessage from './en.json'
import frMessage from './fr.json'
import jpMessage from './jp.json'
import viMessage from './vi.json'

Vue.use(VueI18n)

const LANGUAGE_CODES = {
  VI: 1,
  EN: 2,
  FR: 3,
  JP: 4
}

const LANGUAGE_CODES_NAME = {
  VI: 'VI',
  EN: 'EN',
  FR: 'FR',
  JP: 'JP'
}

const LANGUAGE_CODES_NUMBER = {
  1: 'VI',
  2: 'EN',
  3: 'FR',
  4: 'JP'
}

const LANGUAGE_CODES_FOR_DISEASE = {
  VI: 1,
  EN: 2,
  FR: 3,
  JP: 4
}

const LANGUAGE_CODES_NAME_FOR_DISEASE = {
  VI: 'VI',
  EN: 'EN',
  FR: 'FR',
  JP: 'JP'
}

const LANGUAGE_CODES_NUMBER_FOR_DISEASE = {
  1: 'VI',
  2: 'EN',
  3: 'FR',
  4: 'JP'

}

export const getCurrentLanguage = () => {
  let currentLanguage = window.localStorage.getItem('lang')
  if (
    !currentLanguage ||
    currentLanguage !== 'en' ||
    currentLanguage !== 'fr' ||
    currentLanguage !== 'jp'

  ) {
    currentLanguage = 'vi'
  }

  return currentLanguage.toLowerCase()
}

export const getTranslate = (key, components) => {
  if (!LANGUAGE_KEYS[key]) return key

  let currentLanguage = window.localStorage.getItem('lang')

  if (
    !currentLanguage ||
    currentLanguage !== 'en' ||
    currentLanguage !== 'fr' ||
    currentLanguage !== 'jp'

  ) {
    currentLanguage = 'vi'
  }

  const langCode = LANGUAGE_CODES[currentLanguage.toUpperCase()]

  return LANGUAGES[key][langCode]
}

// const getLanguageDataByCode = (langCode) => {
//   if (!langCode) {
//     langCode = LANGUAGE_CODES?.VI
//   }

//   let data = {}

//   Object.entries(LANGUAGES).map(([key, value]) => {
//     data = {
//       ...data,
//       [key]: value[langCode]
//     }
//   })

//   return data
// }

const messages = {
  vi: viMessage,
  en: enMessage,
  fr: frMessage,
  jp: jpMessage
}

const i18n = new VueI18n({
  locale: window.localStorage.lang
    ? window.localStorage.lang
    : LANGUAGE_CODES_NAME?.VI?.toLowerCase(),
  messages,
  fallbackLocale: LANGUAGE_CODES_NAME?.VI?.toLowerCase(),
  silentFallbackWarn: true
})

export {
  LANGUAGE_CODES, LANGUAGE_CODES_FOR_DISEASE, LANGUAGE_CODES_NAME, LANGUAGE_CODES_NAME_FOR_DISEASE, LANGUAGE_CODES_NUMBER, LANGUAGE_CODES_NUMBER_FOR_DISEASE, LANGUAGE_KEYS
}
export default i18n
